import React from "react";

function WorkWithUs() {
    return (
        <div className="work-with-us-container">
            <div>
                <h2 className="workWithUsTitle">Liked our Products?</h2>
                <p>Wanna work on awsome products with us?</p>
            </div>
            <a href="http://bit.ly/3kkH127" target="_blank" rel="noreferrer">
                <button className="work-with-us global-button ">
                    WORK WITH US
                </button>
            </a>
        </div>
    );
}

export default WorkWithUs;
