import React from "react";
import Layout from "../components/common/Layout";
import OpenSourceProject from "../components/common/OpenSourceProject";
import WorkWithUs from "../components/common/WorkWithUs";

import coDoc19 from "../images/products/codoc2.gif";
import ovuli from "../images/products/ovuli2.gif";
import youtubeDetox from "../images/products/youtubeDetox2.gif";
import caligator from "../images/products/caligator2.gif";
import brogrammer from "../images/products/brogrammer2.gif";
import dracula from "../images/products/dragula2.gif";
import nomoogle from "../images/products/nomoogle2.jpg";
import criCLI from "../images/products/cricli2.gif";
import programCLI from "../images/products/program.gif";
import moddoc from "../images/products/moddoc2.gif";
import dev10 from "../images/products/dev102.gif";
import Animatopy from "../images/products/animatopy2.jpg";

import "../styles/app.css";

function products() {
    const productList = [
        {
            title: "CoDoc19",
            description: "Essential COVID-19 info at your fingertips",
            github: "https://github.com/teamxenox/CoDoc19",
            displayImage: { coDoc19 },
        },
        {
            title: "Ovuli",
            description: "Ovulation calculator in your terminal",
            github: "https://github.com/sarthology/ovuli",
            displayImage: { ovuli },
        },
        {
            title: "Youtube Detox",
            description: "Distraction-free Youtube search",
            github: "https://github.com/sarthology/youtube-detox",
            displayImage: { youtubeDetox },
        },
        {
            title: "Caligator",
            description:
                "An open-source calculator & convertor that thinks like you",
            github: "https://github.com/teamxenox/caligator",
            displayImage: { caligator },
        },
        {
            title: "Nomoogle",
            description:
                "Chrome extension that can help you get rid of Google addiction.",
            github: "https://teamxenox.github.io/nomoogle-landing",
            displayImage: { nomoogle },
        },
        {
            title: "Brogrammer",
            description: "Easy fitness for the busy bees",
            github: "https://github.com/sarthology/brogrammers",
            displayImage: { brogrammer },
        },
        {
            title: "CriCLI",
            description: "Live cricket score and commentary on your terminal",
            github: "https://github.com/sarthology/criCLI",
            displayImage: { criCLI },
        },
        {
            title: "ProGram CLI",
            description: "CLI based, self-hosted Instagram-esque gallery",
            github: "https://github.com/sarthology/proGramCLI",
            displayImage: { programCLI },
        },
        {
            title: "ModDoc",
            description:
                "Easily read the documentation for any node modules in your projects.",
            github: "https://github.com/sarthology/moddoc",
            displayImage: { moddoc },
        },
        {
            title: "Dev10",
            description: "Catch the top DEV.to posts in a nifty menubar app",
            github: "https://github.com/sarthology/Dev10",
            displayImage: { dev10 },
        },
        {
            title: "Dracula",
            description: "A free stock images tool on steroids 🦇",
            github: "https://github.com/sarthology/dragula",
            displayImage: { dracula },
        },
        {
            title: "Animatopy",
            description:
                "Just-add-water CSS animations snippets for animate.css library.",
            github: "https://github.com/sarthology/Animatopy",
            displayImage: { Animatopy },
        },
    ];

    return (
        <Layout>
            <div className="product-heading">
                <h1>Our Projects</h1>
            </div>
            <div className="product-container">
                {productList
                    .slice(0, productList.length / 2)
                    .map((value, index) => (
                        <OpenSourceProject product={value} key={index} />
                    ))}

                <WorkWithUs />

                {productList
                    .slice(productList.length / 2, productList.length)
                    .map((value, index) => (
                        <OpenSourceProject product={value} key={index} />
                    ))}
            </div>
        </Layout>
    );
}

export default products;
