/* eslint-disable react/prop-types */
import React from "react";
import "../../styles/app.css";

function OpenSourceProject({ product }) {
    return (
        <div className="single-product">
            <h1 className="item-title">{product.title}</h1>

            <div className="product-detail-container">
                <p className="product-excerpt">{product.description}</p>

                <div className="product-image-container">
                    <img
                        className="product-image"
                        src={Object.values(product.displayImage)[0]}
                        alt="product"
                    />
                </div>
            </div>

            <a href={product.github} target="_blank" rel="noreferrer">
                <button className="global-button">View Project</button>
            </a>
        </div>
    );
}

export default OpenSourceProject;
